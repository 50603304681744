<template>
    <div class="d-flex justify-center my-16">
        <v-progress-circular
                :size="100"
                :color="color"
                width="10"
                indeterminate
        ></v-progress-circular>
    </div>
</template>

<script>
export default {
    name: "TheSpinner",
    props: {
        color: {
            type: String,
            required: false,
            default: "red"
        }
    }
}
</script>
